<template>
  <b-modal
    id="password-change-modal"
    centered
    :title="$t('users.change_password')"
    :ok-title="$t('general.save')"
    :cancel-title="$t('general.cancel')"
    @ok="saveChanges"
  >
    <validation-observer ref="newPasswordValidation">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="v-password-new-user">{{ $t('users.password') }}</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="v-password-new-user"
                    v-model="new_password"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="v-password-new-user"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="v-password-confirm-new-user">{{ $t('users.confirm_password') }}</label>
              </div>
              <validation-provider
                #default="{ errors }"
                :name="$t('users.confirm_password')"
                rules="required|confirm:@password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="v-password-confirm-new-user"
                    v-model="password_confirm"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="v-password-confirm-new-user"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal, BInputGroup, BInputGroupAppend, BForm, BFormGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapActions } from 'vuex'

extend('confirm', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'Паролы не совпадають',
})

export default {
  components: {
    BModal,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      new_password: '',
      password_confirm: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    ...mapActions('users', ['UPDATE_USER']),
    saveChanges() {
      this.$refs.newPasswordValidation.validate().then(success => {
        if (success) {
          const passwordObj = {
            password: this.new_password,
            id: this.$route.params.id,
          }
          this.UPDATE_USER(passwordObj).then(() => {
            this.$_successToast(this.$t('general.edited'))
            this.password = ''
            this.password_confirm = ''
          }).catch(() => {
            this.$_errorToast(this.$t('general.error'))
          })
        }
      })
    },
  },
}
</script>

<style>

</style>
