<template>
  <b-modal
    id="edit-user-subjects-modal"
    :title="$t('users.editing_user_details')"
    centered
    :ok-title="$t('general.save')"
    :cancel-title="$t('general.cancel')"
    no-close-on-backdrop
    size="lg"
    @ok="saveClick"
  >
    <validation-observer ref="editUserSubjectsValidations">
      <b-row>
        <b-col md="12">
          <b-form-group
            :label="$t('references.subjects')"
            label-for="subjects"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('references.subjects')"
            >
              <v-select
                id="subjects"
                v-model="user_data.subjects"
                multiple
                :options="GET_SUBJECTS_LIST.results"
                :get-option-label="el => el.title[$i18n.locale]"
                :reduce="opt => opt.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            :label="$t('others.supervisor')"
            label-for="subjects"
          >
            <validation-provider
              #default="{ errors }"
              rules=""
              :name="$t('others.supervisor')"
            >
              <v-select
                id="supervisor"
                v-model="user_data.supervisor"
                :options="GET_USERS_LIST.results"
                multiple
                :get-option-label="el => el.contact.full_name"
                :reduce="opt => opt.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import { required, email } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      user_data: {},
      params: {
        page_size: 100,
        role__unique_name: 'supervisor',
      },
    }
  },
  computed: {
    ...mapGetters('knowledgeBase', ['GET_SUBJECTS_LIST', 'GET_GROUPS_LIST']),
    ...mapGetters('users', ['GET_USERS_LIST']),
  },
  watch: {
    userData() {
      this.user_data = this.userData
    },
  },
  mounted() {
    this.FETCH_SUBJECTS_LIST({ page_size: 100 })
    this.FETCH_GROUPS_LIST()
    this.FETCH_USERS_LIST(this.params)
  },
  methods: {
    ...mapActions('knowledgeBase', ['FETCH_SUBJECTS_LIST', 'ATTACH_SUBJECTS_TO_TEACHER', 'FETCH_GROUPS_LIST']),
    ...mapActions('users', ['FETCH_USERS_LIST']),
    async saveClick(bvModalEvt) {
      bvModalEvt.preventDefault()
      const success = await this.$refs.editUserSubjectsValidations.validate()
      if (!success) return
      const {
        contact, subjects, supervisor,
      } = this.user_data
      console.log(this.user_data)
      Promise.all([
        // this.EDIT_ONE_CONTACT(contact),
        this.ATTACH_SUBJECTS_TO_TEACHER({
          subject: subjects, teacher: contact.id, supervisor,
        }),
      ]).then(() => {
        this.$_successToast(this.$t('general.success'))
        this.$emit('saveSuccess')
      })
    },
  },
}
</script>

<style>

</style>
