<template>
  <div>
    <full-table
      :other-filter-props="{
        add: true,
      }"
      :no-body="true"
      :items="GET_TEACHERS_ATTACHED_SUPERVISOR.results"
      :fields="fields"
      :rows="GET_TEACHERS_ATTACHED_SUPERVISOR.count"
      :edit-btn="false"
      :delete-btn="true"
      :first-params="params"
      :empty-text="$t('general.no_data_to_view')"
      @refresh="FETCH_TEACHERS_ATTACHED_SUPERVISOR"
      @row-clicked="(data) => $router.push(`/users/${data.id}/info`)"
      @detail-button-clicked="(id) => $router.push(`/users/${id}/info`)"
      @add-button-clicked="addUser"
      @delete-button-clicked="onDelete"
    />
    <AttachTeacherModal
      :contact-id="contactId"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import toast from '@/mixins/toast'
import areYouSure from '@/mixins/areYouSure'
import FullTable from '@/components/FullTable.vue'
import AttachTeacherModal from '../modals/AttachTeacherModal.vue'

export default {
  components: {
    FullTable,
    AttachTeacherModal,
  },
  directives: {
    Ripple,
  },
  mixins: [toast, areYouSure],
  props: {
    contactId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      defRole: 'teacher',
      params: {
        page_size: 10,
        page: 1,
        ordering: null,
        search: null,
        id: this.$route.params.id,
      },
      fields: [
        {
          key: 'contact.id',
          label: 'ID',
          sortable: true,
        },
        {
          label: 'users.full_name',
          key: 'contact.full_name',
          sortable: true,
        },
        {
          key: 'phones',
          label: 'users.phone_number',
        },
        {
          label: 'users.email',
          key: 'contact.email',
        },
        // {
        //   label: 'Профиль (предмет)',
        //   key: 'sciences',
        // },
        {
          label: 'users.level',
          key: 'contact.level',
        },
        // {
        //   label: 'users.count_students',
        //   key: 'contact.number_students',
        // },
        // {
        //   label: 'users.status',
        //   key: 'contact.is_active',
        // },
        {
          label: 'general.actions',
          key: 'actions',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('users', ['GET_TEACHERS_ATTACHED_SUPERVISOR']),
  },
  methods: {
    ...mapMutations('users', ['SET_USERS_LIST']),
    ...mapActions('users', ['FETCH_TEACHERS_ATTACHED_SUPERVISOR', 'DELETE_TEACHERS_NOT_ATTACHED_SUPERVISOR']),
    ...mapActions('contacts', ['EDIT_ONE_CONTACT']),
    addUser() {
      this.$bvModal.show('attach-teacher')
    },
    refresh() {
      this.FETCH_TEACHERS_ATTACHED_SUPERVISOR(this.params)
    },
    onDelete(id) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          this.DELETE_TEACHERS_NOT_ATTACHED_SUPERVISOR({ id: this.$route.params.id, teacher: id })
            .then(() => {
              // this.$_successToast(this.$t('general.removed'))
              this.$_successToast(this.$t('general.removed'))
              this.params.page = 1
              this.refresh()
            })
            .catch(() => {
              this.$_errorToast(this.$t('general.error'))
            })
        }
      })
    },
  },

}
</script>

<style>

</style>
