<template>
  <div>
    <auth-log-table :logs="GET_USER_ACTIVITY.results" />
    <custom-pagination
      :total-rows="GET_USER_ACTIVITY.count"
      :query-params="query_params"
      @changePage="changePage"
      @changePerPage="changePerPage"
    />
  </div>
</template>

<script>
import {
// BFormInput, BButton,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import authLogTable from '../tables/authLogTable.vue'
import customPagination from '@/components/customPagination.vue'

export default {
  components: {
    authLogTable,
    customPagination,
  },
  data() {
    return {
      query_params: {
        page: 1,
        page_size: 10,
        user: this.$route.params.id,
      },
    }
  },
  computed: {
    ...mapGetters('users', ['GET_USER_ACTIVITY']),
  },
  mounted() {
    this.FETCH_USER_ACTIVITY(this.query_params)
  },
  methods: {
    ...mapActions('users', ['FETCH_USER_ACTIVITY']),
    changePage(page) {
      this.query_params.page = page
      this.FETCH_USER_ACTIVITY(this.query_params)
    },
    changePerPage(per_page) {
      this.query_params.page = 1
      this.query_params.page_size = per_page
      this.FETCH_USER_ACTIVITY(this.query_params)
    },
  },
}
</script>

<style>

</style>
