<template>
  <div>
    <b-card no-body>
      <b-list-group>
        <b-list-group-item
          class="d-flex justify-content-between align-items-center"
        >
          <div>
            <span class="mr-1">
              <feather-icon
                icon="UserIcon"
                size="16"
              />
            </span>
            <b>
              {{ $t("users.id") }}
            </b>
          </div>
          <div
            class="d-flex justify-content-end text-right align-items-center w-50"
          >
            <b>{{ info.id }}</b>
            <feather-icon
              v-if="
                $can('manage', 'admin') ||
                  $can('manage', 'super_admin') ||
                  $can('manage', 'head_manager') ||
                  $can('manage', 'id_manager')
              "
              class="text-warning ml-50 cursor-pointer"
              icon="EditIcon"
              size="22"
              @click="$emit('editClick')"
            />
          </div>
        </b-list-group-item>
        <b-list-group-item
          class="d-flex justify-content-between align-items-center"
        >
          <div>
            <span class="mr-1">
              <feather-icon
                icon="UserIcon"
                size="16"
              />
            </span>
            <span>{{ $t("users.full_name") }}</span>
          </div>
          <div
            class="d-flex justify-content-end text-right align-items-center w-50"
          >
            {{ info.full_name }}
          </div>
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between">
          <div>
            <span class="mr-1">
              <feather-icon
                icon="AwardIcon"
                size="16"
              />
            </span>
            <span>{{ $t("users.gender") }}</span>
          </div>
          <div
            v-if="$data.$_gender_options.find((el) => el.key === info.gender)"
            class="d-flex justify-content-end align-items-center"
          >
            {{
              $data.$_gender_options.find((el) => el.key === info.gender).title[
                $i18n.locale
              ]
            }}
          </div>
        </b-list-group-item>
        <b-list-group-item
          class="d-flex justify-content-between align-items-center"
        >
          <div>
            <span class="mr-1">
              <feather-icon
                icon="GitMergeIcon"
                size="16"
              />
            </span>
            <span>{{ $t("users.branch") }}</span>
          </div>
          <div
            class="d-flex justify-content-end text-right align-items-center w-50"
          >
            {{ info.branch.title }}
          </div>
        </b-list-group-item>
        <b-list-group-item
          v-if="
            $can('manage', 'admin') ||
              $can('manage', 'super_admin') ||
              $can('manage', 'head_manager') ||
              $can('manage', 'id_manager')
          "
          class="d-flex justify-content-between"
        >
          <div>
            <span class="mr-1">
              <feather-icon
                icon="AwardIcon"
                size="16"
              />
            </span>
            <span class="text-uppercase">{{ $t("users.role") }}</span>
          </div>
          <div
            v-if="info.roles && info.roles.length"
            class="d-flex justify-content-end align-items-center flex-wrap"
          >
            <b-badge
              v-for="role in info.roles"
              :key="role.id"
              variant="primary"
              class="ml-50"
            >
              {{ role.title[$i18n.locale] }}
            </b-badge>
          </div>
        </b-list-group-item>
        <b-list-group-item
          v-for="(phone, idx) in info.phones"
          :key="idx"
          class="d-flex justify-content-between"
        >
          <div>
            <span class="mr-1">
              <feather-icon
                icon="PhoneIcon"
                size="16"
              />
            </span>
            <span>{{ $t("users.phone_number") }}</span>
          </div>
          <div class="d-flex justify-content-end align-items-center">
            {{ $_phoneNumberFormatter(phone) }}
          </div>
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between">
          <div>
            <span class="mr-1">
              <feather-icon
                icon="MailIcon"
                size="16"
              />
            </span>
            <span>{{ $t("users.email") }}</span>
          </div>
          <div class="d-flex justify-content-end align-items-center">
            <a
              v-if="info.email"
              :href="`mailto:${info.email}`"
            >
              {{ info.email }}
            </a>
            <span v-else>
              {{ $t("general.no_info") }}
            </span>
          </div>
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between">
          <div>
            <span class="mr-1">
              <feather-icon
                icon="BarChartIcon"
                size="16"
              />
            </span>
            <span>{{ $t("users.level") }}</span>
          </div>
          <div>
            <h4 class="mb-0">
              {{ info.level }}
              <span
                class="text-muted mb-0"
                style="font-size: 15px"
              >LVL</span>
            </h4>
          </div>
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between">
          <div>
            <span class="mr-1">
              <feather-icon
                icon="CalendarIcon"
                size="16"
              />
            </span>
            <span>{{ $t("users.date_joined") }}</span>
          </div>
          <div>{{ info.date_joined }}</div>
        </b-list-group-item>
        <b-list-group-item
          v-if="
            $can('manage', 'admin') ||
              $can('manage', 'super_admin') ||
              $can('manage', 'head_manager') ||
              $can('manage', 'id_manager')
          "
          class="d-flex justify-content-between"
        >
          <div>
            <span class="mr-1">
              <feather-icon
                icon="CreditCardIcon"
                size="16"
              />
            </span>
            <span>{{ $t("users.mutual_settlements") }}</span>
          </div>
          <div>
            <b-form-checkbox
              v-model="info.hide_transaction"
              switch
              disabled
            />
          </div>
        </b-list-group-item>
        <b-list-group-item
          v-if="
            $can('manage', 'admin') ||
              $can('manage', 'super_admin') ||
              $can('manage', 'head_manager') ||
              $can('manage', 'id_manager')
          "
          class="d-flex justify-content-between"
        >
          <div>
            <span class="mr-1">
              <feather-icon
                icon="UserCheckIcon"
                size="16"
              />
            </span>
            <span>{{ $t("users.status") }}</span>
          </div>
          <div>
            <b-form-checkbox
              v-model="info.is_active"
              switch
              disabled
            />
          </div>
        </b-list-group-item>
        <b-list-group-item
          v-if="
            $can('manage', 'admin') ||
              $can('manage', 'super_admin') ||
              $can('manage', 'head_manager') ||
              $can('manage', 'id_manager')
          "
          class="d-flex justify-content-between"
        >
          <div>
            <span class="mr-1">
              <feather-icon
                icon="UserIcon"
                size="16"
              />
            </span>
            <span>{{ $t("users.login") }}</span>
          </div>
          <div>
            {{ info.username }}
          </div>
        </b-list-group-item>
        <b-list-group-item
          v-if="
            $can('manage', 'admin') ||
              $can('manage', 'super_admin') ||
              $can('manage', 'head_manager') ||
              $can('manage', 'id_manager')
          "
          class="d-flex justify-content-between align-items-center"
        >
          <div>
            <span class="mr-1">
              <feather-icon
                icon="LockIcon"
                size="16"
              />
            </span>
            <span>{{ $t("users.password") }}</span>
          </div>
          <div class="d-flex align-items-center">
            <span
              v-if="!show_password && info.password"
              class="mr-50"
            >{{
              "*".repeat(info.password.length)
            }}</span>
            <span
              v-else
              class="mr-50"
            >{{ info.password }}</span>
            <feather-icon
              icon="EyeIcon"
              size="20"
              @click="onChangeShowPassword"
            />
          </div>
        </b-list-group-item>
        <b-list-group-item
          class="d-flex justify-content-between align-items-center"
        >
          <div>
            <span class="mr-1">
              <feather-icon
                icon="TagIcon"
                size="16"
              />
            </span>
            <span>{{ $t("others.tags") }}</span>
          </div>
          <div
            v-if="info.tags && info.tags.length"
            class="d-flex flex-column justify-content-end align-items-center"
          >
            <b-badge
              v-for="tag in info.tags"
              :key="tag.id"
              variant="primary"
              class="ml-50 mt-50"
            >
              {{ tag.title }}
            </b-badge>
          </div>
        </b-list-group-item>
        <!-- <b-list-group-item>
          <div class="d-flex justify-content-end">
            <b-button
              size="sm"
              variant="warning"
              @click="$emit('editClick')"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </div>
        </b-list-group-item> -->
      </b-list-group>
      <!-- <div
        v-for="(val, key) in info"
        :key="key"
        class="text-white mb-50"
      >
        <feather-icon
          size="20"
          :icon="icons[key]"
        />
        {{ val }}
      </div>
      <div class="d-flex">
        <b-button
          class="p-50 ml-auto"
          variant="outline-light"
          @click="$emit('editClicked')"
        >
          <feather-icon icon="EditIcon" />
        </b-button>
      </div> -->
    </b-card>
    <b-card
      v-if="info.roles.some((e) => e.unique_name === 'teacher')"
      no-body
    >
      <b-list-group>
        <b-list-group-item>
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="m-0">
              {{ $t("general.edit") }}
            </h5>
            <b-button
              size="sm"
              variant="warning"
              class="cursor-pointer"
              @click="$emit('editSubjectsClick')"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </div>
        </b-list-group-item>
        <b-list-group-item
          class="d-flex justify-content-between align-items-center"
        >
          <div>
            <span class="mr-1">
              <feather-icon
                icon="BookIcon"
                size="16"
              />
            </span>
            <span>{{ $t("references.subjects") }}</span>
          </div>
          <div
            v-if="info.subjects && info.subjects.length"
            class="d-flex flex-column justify-content-end align-items-center"
          >
            <b-badge
              v-for="subject in info.subjects"
              :key="subject.id"
              variant="primary"
              class="ml-50 mt-50"
            >
              {{ subject.title[$i18n.locale] }}
            </b-badge>
          </div>
        </b-list-group-item>
        <b-list-group-item
          class="d-flex justify-content-between align-items-center"
        >
          <div>
            <span class="mr-1">
              <feather-icon
                icon="UserIcon"
                size="16"
              />
            </span>
            <span>{{ $t("others.supervisor") }}</span>
          </div>
          <div
            v-if="info.supervisor"
            class="d-flex flex-column justify-content-end align-items-center"
          >
            <b-badge
              v-for="(item, index) in info.supervisor"
              :key="index"
              variant="primary"
              class="ml-50 mt-50"
            >
              {{ item.full_name }}
            </b-badge>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BListGroup,
  BListGroupItem,
  BBadge,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import formatter from '@/mixins/extra'

export default {
  components: {
    BCard,
    // BButton,
    BListGroup,
    BListGroupItem,
    BBadge,
    BFormCheckbox,
    BButton,
  },
  mixins: [formatter],
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        username: 'PocketIcon',
        email: 'MailIcon',
        phone: 'PhoneIcon',
      },
      show_password: false,
    }
  },
  methods: {
    onChangeShowPassword() {
      this.show_password = !this.show_password
    },
  },
}
</script>

<style></style>
