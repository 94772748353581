<template>
  <b-row>
    <b-col md="3">
      <profile-photo
        ref="profilePhoto"
        :photo-url="GET_USER.contact.photo"
        @saveClick="changePhoto"
      />
      <contact-info-card
        :info="getUserMainInfo"
        @editClick="openEditDetailsModal"
        @editSubjectsClick="openEditSubjectsModal"
      />
    </b-col>
    <b-col md="9">
      <b-card>
        <b-tabs>
          <b-tab
            v-if="getUserRoleUniqueNames.some((r) => ['student'].includes(r))"
            :title="$t('users.courses')"
            active
          >
            <div>
              <hub-reg-tab
                v-if="GET_USER.contact.id"
                :contact-id="GET_USER.contact.id"
                :is-student="getUserRoleUniqueNames.includes('student')"
              />
            </div>
          </b-tab>
          <b-tab
            v-if="getUserRoleUniqueNames.some((r) => ['student'].includes(r))"
            :title="$t('courses.courses_by_class')"
          >
            <div>
              <courses-in-class-table
                :contact-id="GET_USER.contact.id"
              />
            </div>
          </b-tab>
          <b-tab
            v-if="getUserRoleUniqueNames.includes('teacher')"
            :title="$t('classes.students')"
          >
            <students
              ref="students"
              :contact-id="getContactId"
            />
          </b-tab>
          <b-tab
            v-if="getUserRoleUniqueNames.includes('supervisor')"
            :title="$t('others.teachers_list')"
          >
            <TeachersAttachedSupervisor
              v-if="getUserRoleUniqueNames.includes('supervisor')"
              :contact-id="getUserId"
            />
          </b-tab>
          <b-tab
            v-if="getUserRoleUniqueNames.includes('teacher')"
            :title="$t('courses.students_by_classes')"
          >
            <enrolled-students-class :contact-id="getContactId" />
          </b-tab>
          <b-tab :title="$t('users.actions_history')">
            <auth-logs-tab />
          </b-tab>
        </b-tabs>
      </b-card>
    </b-col>
    <edit-user-details-modal
      :user-data="user_data_to_edit"
      @saveSuccess="refresh"
    />
    <edit-user-subjects-modal
      :user-data="user_subjects_to_edit"
      @saveSuccess="refresh"
    />
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BTabs, BTab,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import contactInfoCard from './components/contactInfoCard.vue'
import profilePhoto from '@/components/profilePhoto.vue'
import hubRegTab from './components/tabs/HubRegTab.vue'
import authLogsTab from './components/tabs/authLogsTab.vue'
import formatter from '@/mixins/extra'
import editUserDetailsModal from './components/modals/editUserDetailsModal.vue'
import editUserSubjectsModal from './components/modals/editUserSubjectsModal.vue'
import students from './components/tabs/students.vue'
import CoursesInClassTable from './components/tables/CoursesInClassTable.vue'
import EnrolledStudentsClass from './components/tabs/EnrolledStudentsClass.vue'
import TeachersAttachedSupervisor from './components/tabs/TeachersAttachedSupervisor.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTabs,
    BTab,
    contactInfoCard,
    hubRegTab,
    authLogsTab,
    profilePhoto,
    editUserDetailsModal,
    editUserSubjectsModal,
    students,
    CoursesInClassTable,
    EnrolledStudentsClass,
    TeachersAttachedSupervisor,
  },
  mixins: [formatter],
  data() {
    return {
      user_data_to_edit: {},
      user_subjects_to_edit: {},
    }
  },
  computed: {
    ...mapGetters('users', ['GET_USER']),
    ...mapGetters('contacts', ['GET_SUBJECTS_OF_CONTACT']),
    ...mapGetters('contacts', ['GET_COURSES_BY_CONTACT']),
    getUserMainInfo() {
      const {
        username,
        roles,
        contact,
        date_joined,
        show_password,
        is_active,
        // branch,
      } = this.GET_USER
      return {
        id: contact.id,
        username: username || `${contact.first_name} ${contact.last_name}`,
        phones: contact.phones,
        full_name: `${contact.last_name} ${contact.first_name} ${contact.middle_name}`,
        email: contact.email || this.$t('general.no_info'),
        branch: {
          id: contact.branch?.id || this.$t('general.no_info'),
          title: contact.branch?.title || this.$t('general.no_info'),
        },
        roles,
        is_active,
        level: contact.level,
        date_joined: this.$_dateTimeFormatter(date_joined),
        subjects: this.GET_SUBJECTS_OF_CONTACT,
        gender: contact.gender,
        supervisor: contact.supervisor,
        // tags: contact.tags,
        hide_transaction: contact.hide_transaction,
        password: show_password,
      }
    },
    getContactId() {
      return this.GET_USER.contact.id
    },
    getUserId() {
      return this.GET_USER.id
    },
    getUserRoleUniqueNames() {
      return this.GET_USER.roles.map(el => el.unique_name)
    },
  },
  watch: {
    '$route.params.id': {
      deep: true,
      handler(id) {
        console.log(id)
        this.FETCH_USER(this.$route.params.id).then(() => {
          this.updateBreadcrumb()
          if (this.getUserRoleUniqueNames.includes('teacher')) {
            this.$refs.students.onMount()
          }
          this.FETCH_SUBJECTS_OF_CONTACT(this.GET_USER.contact.id)
          this.fetchCourses(1)
        })
      },
    },
  },
  mounted() {
    this.FETCH_USER(this.$route.params.id).then(() => {
      this.updateBreadcrumb()
      if (this.getUserRoleUniqueNames.includes('teacher')) {
        this.$refs.students.onMount()
      }
      this.FETCH_SUBJECTS_OF_CONTACT(this.GET_USER.contact.id)
      this.fetchCourses(1)
      this.FETCH_COURSES_IN_CLASS(this.GET_USER.contact.id)
    })
  },
  methods: {
    ...mapActions('users', ['FETCH_USER']),
    ...mapActions('contacts', [
      'EDIT_ONE_CONTACT',
      'FETCH_SUBJECTS_OF_CONTACT',
      'FETCH_COURSES_BY_CONTACT',
    ]),
    ...mapActions('courses', ['FETCH_COURSES_IN_CLASS']),
    ...mapMutations('breadcrumb', ['UPDATE_BREADCRUMB']),
    updateBreadcrumb() {
      const [first, second, third] = this.$_removeObjectConnection(
        this.$route.meta.breadcrumb,
      )
      if (this.$can('manage', 'teacher')) {
        second.text = this.GET_USER.contact.first_name
        this.UPDATE_BREADCRUMB([second])
      } else {
        second.text = this.GET_USER.contact.first_name
        this.UPDATE_BREADCRUMB([first, second, third])
      }
    },
    fetchCourses(next) {
      this.FETCH_COURSES_BY_CONTACT({
        id: this.GET_USER.contact.id,
        student_id: this.GET_USER.contact.id,
        page_size: 1,
        page: next,
      })
        .then(res => {
          if (res.next) {
            this.fetchCourses(res.next)
          }
        })
    },
    changePhoto(file) {
      this.EDIT_ONE_CONTACT(
        this.$_objectToFormData({ id: this.getContactId, photo: file }),
      ).then(() => {
        this.$_successToast(this.$t('general.change_success'))
        this.FETCH_USER(this.$route.params.id)
          .then()
          .then(() => {
            this.$refs.profilePhoto.cancelChange()
          })
      })
    },
    refresh() {
      this.FETCH_USER(this.$route.params.id).then(() => {
        this.FETCH_SUBJECTS_OF_CONTACT(this.GET_USER.contact.id)
      })
      this.$bvModal.hide('edit-user-details-modal')
      this.$bvModal.hide('edit-user-subjects-modal')
    },
    openEditDetailsModal() {
      const {
        contact, roles, username, ...others
      } = this.GET_USER
      const {
        author, branch, photo, ...rest
      } = contact
      this.user_data_to_edit = {
        roles: roles.map(el => el.id),
        role_unique_names: roles.map(el => el.unique_name),
        username:
          username.slice(0, branch.branch_code?.length) === branch.branch_code
            ? username.slice(branch.branch_code?.length)
            : username,
        ...others,
        branch,
        contact: {
          ...rest,
        },
      }
      this.$bvModal.show('edit-user-details-modal')
    },
    openEditSubjectsModal() {
      const { contact } = this.GET_USER
      this.user_subjects_to_edit = {
        subjects: this.GET_SUBJECTS_OF_CONTACT.map(el => el.id),
        contact: {
          id: contact.id,
        },
        supervisor: contact.supervisor.id,
      }
      this.$bvModal.show('edit-user-subjects-modal')
    },
  },
}
</script>

<style></style>
