<template>
  <b-modal
    id="edit-user-details-modal"
    :title="$t('users.editing_user_details')"
    centered
    :ok-title="$t('general.save')"
    :cancel-title="$t('general.cancel')"
    no-close-on-backdrop
    size="lg"
    @ok="saveClick"
  >
    <!-- {{ GET_APPROVED_BRANCH.results }} -->
    <validation-observer ref="editUserDetailsValidations">
      <b-row>
        <b-col md="4">
          <b-form-group :label="$t('users.last_name')" label-for="last-name">
            <validation-provider
              #default="{ errors }"
              rules=""
              :name="$t('users.last_name')"
            >
              <b-form-input
                id="last-name"
                v-model="user_data.contact.last_name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            :label="$t('users.first_name') + '*'"
            label-for="first-name"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('users.first_name')"
            >
              <b-form-input
                id="first-name"
                v-model="user_data.contact.first_name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            :label="$t('users.middle_name')"
            label-for="middle-name"
          >
            <validation-provider
              #default="{ errors }"
              rules=""
              :name="$t('users.middle_name')"
            >
              <b-form-input
                id="middle-name"
                v-model="user_data.contact.middle_name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group :label="$t('users.gender') + ':'" label-for="gender">
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('users.gender')"
            >
              <b-form-radio-group
                id="gender"
                v-model="user_data.contact.gender"
                :options="$data.$_gender_options"
                :text-field="`title.${$i18n.locale}`"
                value-field="key"
                name="radio-inline"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            :label="$t('users.mutual_settlements') + ':'"
            label-for="gender"
          >
            <validation-provider
              #default="{ errors }"
              rules=""
              :name="$t('users.mutual_settlements')"
            >
              <b-form-checkbox
                v-model="user_data.contact.hide_transaction"
                switch
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group :label="$t('users.status') + ':'" label-for="gender">
            <validation-provider
              #default="{ errors }"
              rules=""
              :name="$t('users.mutual_settlements')"
            >
              <b-form-checkbox v-model="user_data.is_active" switch />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <div class="d-flex align-items-end h-100 pb-1">
            <b-button
              v-b-modal.password-change-modal
              variant="warning"
              class="w-100"
            >
              {{ $t("users.change_password") }}
            </b-button>
          </div>
        </b-col>

        <b-col md="6">
          <b-form-group :label="$t('users.email')" label-for="email">
            <validation-provider
              #default="{ errors }"
              rules="email"
              :name="$t('users.email')"
            >
              <b-form-input v-model="user_data.contact.email" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group :label="$t('users.roles')" label-for="roles">
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('users.roles')"
            >
              <v-select
                id="roles"
                v-model="user_data.roles"
                multiple
                :options="GET_USER_ROLES_LIST.results"
                :get-option-label="(el) => el.title[$i18n.locale]"
                :reduce="(opt) => opt.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group :label="$t('users.level') + '*'" label-for="roles">
            <validation-provider
              #default="{ errors }"
              rules=""
              :name="$t('users.level')"
            >
              <v-select
                id="level"
                v-model="user_data.contact.level"
                :options="GET_LEVELS_LIST"
                label="level"
                :reduce="(opt) => opt.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- <b-col md="4">
          <b-form-group
            :label="$t('others.tags')"
            label-for="tags"
          >
            <validation-provider
              #default="{ errors }"
              rules=""
              :name="$t('others.tags')"
            >
              <v-select
                id="tags"
                v-model="user_data.contact.tags"
                multiple
                :options="GET_GROUPS_LIST.results"
                label="title"
                :reduce="(opt) => opt.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col> -->
        <b-col md="6">
          <b-form-group :label="$t('users.login')" label-for="loginInput">
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('users.login')"
            >
              <b-input-group>
                <b-input-group-prepend is-text>
                  {{ user_data.branch.branch_code }}
                </b-input-group-prepend>
                <b-form-input
                  id="loginInput"
                  v-model="user_data.username"
                  :placeholder="$t('users.login')"
                  :state="validateState(errors)"
                  name="reset-password-login"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- <b-col md="6">
          <b-form-group :label="$t('users.roles')" label-for="roles">
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('users.roles')"
            >
              <v-select
                id="roles"
                v-model="user_data.branch"
                :options="GET_APPROVED_BRANCH.results"
                :get-option-label="(el) => el.title"
                :reduce="(opt) => opt.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col> -->

        <b-col md="12">
          <div
            v-for="(phone, idx) in phones"
            :key="idx"
            class="d-flex justify-content-between align-items-center"
          >
            <b-form-group
              :label="$t('users.phone_number') + ':*'"
              label-for="phone_number"
              class="w-100"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
                :name="$t('users.phone_number') + idx"
              >
                <!-- <masked-input v-model="user_data.contact.phone" /> -->
                <masked-input v-model="phone.value" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button
              variant="primary"
              size="sm"
              class="mt-50 ml-1"
              @click="repeateAgain"
            >
              <feather-icon class="mr-25" icon="PlusIcon" />
            </b-button>

            <b-button
              v-if="phones.length > 1"
              variant="danger"
              class="mt-50 ml-1"
              size="sm"
              @click="removeItem(idx)"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
    <edit-password-modal />
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BButton,
  BFormCheckbox,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";
import maskedInput from "@/components/maskedInput.vue";
import editPasswordModal from "./editPasswordModal.vue";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    maskedInput,
    vSelect,
    editPasswordModal,
    BButton,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      phones: [{ value: "" }],
      required,
      email,
      user_data: {},
    };
  },
  computed: {
    ...mapGetters("users", ["GET_USER_ROLES_LIST", "GET_LEVELS_LIST"]),
    ...mapGetters("knowledgeBase", ["GET_SUBJECTS_LIST", "GET_GROUPS_LIST"]),
    // ...mapGetters("branches", ["GET_APPROVED_BRANCH"]),
  },
  watch: {
    userData() {
      this.user_data = this.userData;
      this.phones = this.userData.contact.phones.map((phone) => ({
        value: phone,
      }));
      if (this.phones.length === 0) {
        this.phones.push({ value: "" });
      }
    },
  },
  mounted() {
    this.FETCH_USER_ROLES_LIST();
    this.FETCH_SUBJECTS_LIST({ page_size: 100 });
    // this.FETCH_APPROVED_BRANCH_LIST();
  },
  methods: {
    ...mapActions("users", ["FETCH_USER_ROLES_LIST", "UPDATE_USER"]),
    ...mapActions("contacts", ["EDIT_ONE_CONTACT"]),
    ...mapActions("knowledgeBase", [
      "FETCH_SUBJECTS_LIST",
      "ATTACH_SUBJECTS_TO_TEACHER",
      "FETCH_GROUPS_LIST",
    ]),
    ...mapActions("branches", ["FETCH_APPROVED_BRANCH_LIST"]),
    async saveClick(bvModalEvt) {
      bvModalEvt.preventDefault();
      const success = await this.$refs.editUserDetailsValidations.validate();
      if (!success) return;
      const { contact, subjects, phones, username, branch, ...rest } =
        this.user_data;
      contact.phones = this.phones.map((phone) => phone.value);
      Promise.all([
        this.UPDATE_USER({ ...rest, username: branch.branch_code + username }),
        this.EDIT_ONE_CONTACT(contact),
        // this.ATTACH_SUBJECTS_TO_TEACHER({ subject: subjects, teacher: contact.id }),
      ])
        .then(() => {
          this.$_successToast(this.$t("general.success"));
          this.$emit("saveSuccess");
        })
        .catch(() => {
          this.$_errorToast(this.$t("general.error"));
        });
    },
    validateState(errors) {
      if (!/[a-zA-Z]+/gm.test(this.user_data.username)) {
        setTimeout(() => {
          if (
            !this.$refs.usersValidation.errors[this.$t("users.login")].includes(
              `Поле ${this.$t(
                "users.login"
              )} должен содержать хотя бы одну букву`
            )
          ) {
            this.$refs.usersValidation.errors[this.$t("users.login")].push(
              `Поле ${this.$t(
                "users.login"
              )} должен содержать хотя бы одну букву`
            );
          }
        }, 100);
        return false;
      }
      return errors.length > 0 ? false : null;
    },
    repeateAgain() {
      this.phones.push({ value: "" });
    },
    removeItem(index) {
      this.phones.splice(index, 1);
    },
  },
};
</script>

<style></style>
