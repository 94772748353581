<template>
  <b-card
    no-body
    class="card-company-table"
  >
    <StudentsList :courses="GET_STUDENTS_IN_COURSE.results" />
    <custom-pagination
      :query-params="params"
      :total-rows="GET_STUDENTS_IN_COURSE.count"
      @changePage="changePage"
      @changePerPage="changePerPage"
    />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import formatter from '@/mixins/extra'
import customPagination from '@/components/customPagination.vue'
import StudentsList from '../tables/StudentsList.vue'

export default {
  components: {
    BCard,
    customPagination,
    StudentsList,
  },
  mixins: [formatter],
  props: {
    contactId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      students: [],
      fields: [
        {
          label: '№',
          key: 'index',
        },
        {
          label: 'users.full_name',
          key: 'full_name',
        },
        {
          label: 'users.class',
          key: 'group',
        },
        {
          label: 'users.level',
          key: 'level',
        },
        {
          label: 'users.phone_number',
          key: 'phone',
        },
        {
          label: 'users.access',
          key: 'access',
        },
      ],
      params: {
        page_size: 10,
        page: 1,
        ordering: null,
        search: null,
      },
    }
  },
  computed: {
    ...mapGetters('courses', ['GET_STUDENTS_IN_COURSE']),
  },
  methods: {
    ...mapActions('contacts', ['FETCH_ONE_CONTACT']),
    ...mapActions('courses', ['FETCH_STUDENTS_IN_COURSE']),
    onMount() {
      this.FETCH_STUDENTS_IN_COURSE({ id: this.contactId, ...this.params })
    },
    changePerPage(page_size) {
      this.params.page_size = page_size
      this.params.page = 1
      this.FETCH_STUDENTS_IN_COURSE({ id: this.contactId, ...this.params })
    },
    changePage(value) {
      this.params.page = value
      this.FETCH_STUDENTS_IN_COURSE({ id: this.contactId, ...this.params })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
