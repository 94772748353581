var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{attrs:{"id":"hub-reg-table","fields":_vm.fields,"items":_vm.GET_CLASS_IN_COURSE,"show-empty":"","empty-text":_vm.$t('general.no_data_to_view'),"responsive":"","striped":"","hover":""},on:{"row-clicked":function (data) { return _vm.$router.push({ name: 'student-course-progress', params: { studentId: _vm.contactId, id: data.course.id, relationId: data.id } }); }},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"cell(subject)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"variant":"info"}},[_vm._v(" "+_vm._s(item.subject.title[_vm.$i18n.locale])+" ")])]}},{key:"cell(access)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$_dateTimeFormatter(item.access_start_date).split(',')[0])+" - "+_vm._s(_vm.$_dateTimeFormatter(item.access_end_date).split(',')[0])+" ")]}},{key:"cell(teachers)",fn:function(ref){
var item = ref.item;
return _vm._l((item.teachers),function(teacher){return _c('b-badge',{key:teacher.id,attrs:{"variant":"info"}},[_vm._v(" "+_vm._s(teacher.full_name)+" ")])})}},{key:"cell(group)",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"cell(chapter_statistics)",fn:function(ref){
var value = ref.value;
return [_c('b-progress',{staticClass:"progress-bar-primary min-width-100",attrs:{"value":value,"max":"100","striped":"","variant":"primary","animated":"","show-value":""}})]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-around"},[(_vm.$can('manage', 'admin') || _vm.$can('manage', 'super_admin') || _vm.$can('manage', 'head_manager'))?[(_vm.edit)?_c('b-button',{staticClass:"p-50 mr-50",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.$emit('editBtnClicked', item)}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1):_vm._e(),(_vm.remove)?_c('b-button',{staticClass:"p-50 mr-50",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.deleteBtnClicked(item.id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1):_vm._e()]:_vm._e(),_c('b-button',{staticClass:"btn-icon mr-50 p-25",attrs:{"variant":"success","size":"sm"},on:{"click":function($event){return _vm.$router.push({ name: 'student-course-progress', params: { studentId: _vm.contactId, id: item.course.id, relationId: item.id } })}}},[_c('feather-icon',{attrs:{"size":"22","icon":"ChevronsRightIcon"}})],1)],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }