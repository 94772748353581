<template>
  <b-table
    id="hub-reg-table"
    :fields="fields"
    :items="GET_CLASS_IN_COURSE"
    show-empty
    :empty-text="$t('general.no_data_to_view')"
    responsive
    striped
    hover
    @row-clicked="data => $router.push({ name: 'student-course-progress', params: { studentId: contactId, id: data.course.id, relationId: data.id } })"
  >
    <template #head()="{ label }">
      {{ $t(label) }}
    </template>
    <template #cell(subject)="{ item }">
      <b-badge variant="info">
        {{ item.subject.title[$i18n.locale] }}
      </b-badge>
    </template>
    <template #cell(access)="{ item }">
      {{ $_dateTimeFormatter(item.access_start_date).split(',')[0] }} - {{ $_dateTimeFormatter(item.access_end_date).split(',')[0] }}
    </template>
    <template #cell(teachers)="{ item }">
      <b-badge
        v-for="teacher in item.teachers"
        :key="teacher.id"
        variant="info"
      >
        {{ teacher.full_name }}
      </b-badge>
    </template>
    <template #cell(group)="{ value }">
      <b-badge variant="success">
        {{ value }}
      </b-badge>
    </template>
    <template #cell(chapter_statistics)="{ value }">
      <!-- <div class="d-flex align-items-center justify-content-center"> -->
      <b-progress
        :value="value"
        max="100"
        striped
        class="progress-bar-primary min-width-100"
        variant="primary"
        animated
        show-value
      />
      <!-- </div> -->
    </template>
    <template #cell(actions)="{ item }">
      <div class="d-flex justify-content-around">
        <template
          v-if="$can('manage', 'admin') || $can('manage', 'super_admin') || $can('manage', 'head_manager')"
        >
          <b-button
            v-if="edit"
            class="p-50 mr-50"
            variant="warning"
            @click="$emit('editBtnClicked', item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            v-if="remove"
            class="p-50 mr-50"
            variant="danger"
            @click="deleteBtnClicked(item.id)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </template>
        <b-button
          variant="success"
          class="btn-icon mr-50 p-25"
          size="sm"
          @click="$router.push({ name: 'student-course-progress', params: { studentId: contactId, id: item.course.id, relationId: item.id } })"
        >
          <feather-icon
            size="22"
            icon="ChevronsRightIcon"
          />
        </b-button>
      </div>
    </template>
  </b-table>
</template>

<script>
import {
  BTable, BButton, BBadge, BProgress,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import extra from '@/mixins/extra'

export default {
  components: {
    BTable,
    BButton,
    BBadge,
    BProgress,
  },
  mixins: [extra],
  props: {
    courses: {
      type: Array,
      required: false,
      default: () => [],
    },
    contactId: {
      type: Number,
      required: true,
    },
    remove: {
      type: Boolean,
      required: false,
      default: true,
    },
    edit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'course.title',
          label: 'users.course_title',
        },
        {
          key: 'course.price',
          label: 'users.price',
        },
        // {
        //   key: 'subject',
        //   label: 'users.subject',
        // },
        {
          key: 'group',
          label: 'users.class',
        },
        {
          key: 'chapter_statistics',
          label: 'users.complited',
        },
        {
          key: 'access',
          label: 'users.access',
        },

        // {
        //   key: 'actions',
        //   label: 'general.actions',
        //   tdClass: 'width-100',
        // },
      ],
    }
  },
  computed: {
    ...mapGetters('courses', ['GET_CLASS_IN_COURSE']),
  },
  methods: {
    deleteBtnClicked(id) {
      this.$_showAreYouSureModal().then(res => {
        if (res) {
          this.$emit('deleteBtnClicked', id)
        }
      })
    },
  },
}
</script>

<style>

</style>
